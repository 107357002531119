// LoadingScreen.js
import React from "react";
import { useTranslation } from "react-i18next";

const LoadingScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="loading-screen">
      <div className="loading-circle"></div>
      <p>{t("Loading...")}</p>
    </div>
  );
};

export default LoadingScreen;
